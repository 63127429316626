<!--
 * @Author: your name
 * @Date: 2021-07-24 14:06:22
 * @LastEditTime: 2021-10-14 15:16:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \xh-web\src\views\Home.vue
-->
<template>
  <div class="home">
    <swiper
      :options="swiperOption"
      ref="mySwiper"
      class="banner"
      v-if="bannerList.length"
    >
      <swiper-slide v-for="(item, index) in bannerList" :key="index"
        ><a
          :style="{ backgroundImage: `url('${item.bannerUrl}')` }"
          class="itemBanner"
          :href="item.bannerLink"
          target="_blank"
        >
        </a
      ></swiper-slide>

      <!-- <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div> -->
    </swiper>
    <!-- 公告 -->
    <div class="notice container flex-h flex-vc">
      <img src="../../assets/img/lb.png" />
      <div class="noticeSwiper">
        <swiper
          :options="noticeOption"
          ref="noticeSwiper"
          v-if="noticeList.length"
        >
          <swiper-slide
            v-for="(item, index) in noticeList"
            :key="index"
            class="flex-h flex-vc"
          >
            <h2
              @click="
                $router.push({ name: 'newsDetail', query: { id: item.id } })
              "
            >
              【通知公告】{{ item.title }}
            </h2>
            <span class="time">{{
              $formatDate(item.releaseTime, "{y}-{m}-{d}")
            }}</span>
          </swiper-slide>
        </swiper>
      </div>

      <div
        class="more"
        @click="$router.push({ name: 'news', query: { typeId: 2 } })"
      >
        更多>>
      </div>
    </div>
    <!-- 志愿活动 -->
    <div class="activity">
      <div
        class="title container flex-h flex-vc"
        style="margin-top: 36px; margin-bottom: 37px"
      >
        <h1>{{ column[4] }}<span>Voluntary Activities</span></h1>
        <p class="more" @click="$router.push({ name: 'activity' })">
          更多活动>>
        </p>
      </div>
      <div class="container">
        <div class="activityList flex-h">
          <dl
            v-for="(item, index) in activityList"
            :key="index"
            @click="
              $router.push({
                name: 'activityDetail',
                query: { id: item.activityId },
              })
            "
          >
            <dt>
              <img :src="item.activityLogo" />
              <div class="tag" v-if="item.status == 0">即将开始</div>
              <div class="tag" v-if="item.status == 1">正在进行</div>
              <div class="tag" v-if="item.status == 2">已结束</div>
            </dt>
            <dd>
              <h1>{{ item.activityName }}</h1>
              <p>
                活动时间：{{
                  $formatDate(item.startTime, "{y}/{m}/{d}")
                }}-{{ $formatDate(item.endTime, "{y}/{m}/{d}") }}
              </p>
              <p>活动地点：{{ item.activityAddr.length>17?item.activityAddr.substr(0,16)+"...":item.activityAddr }}</p>
              <p>计划招募：{{ item.activityMember }}人</p>
              <div class="button">查看详情</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
    <!-- 志愿服务队 -->
    <div class="team">
      <div class="title container flex-h flex-vc">
        <h1>{{ column[5] }}<span>Voluntary Service Team</span></h1>
        <p class="more" @click="$router.push({ name: 'serve' })">
          更多服务队>>
        </p>
      </div>
      <div class="container">
        <ul class="flex-h">
          <li
            class="flex-h"
            v-for="(item, index) in queue"
            :key="index"
            @click="
              $router.push({ name: 'serveDetail', query: { id: item.id } })
            "
          >
            <div class="itemLeft">
              <img class="teamImg" :src="item.logoUrl" />
              <div class="startList flex-h flex-hc flex-vc">
                <img
                  src="@/assets/img/start.png"
                  v-for="(item, index) in item.score"
                  :key="index"
                />
              </div>
            </div>
            <div class="itemRight">
              <h1>{{ item.queueName }}</h1>
              <p>服务队编号：{{ item.queueCode }}</p>
              <p>队员人数：{{ item.queueUserNum }}人</p>
              <div class="tags">
                  主要服务方向：
                  <span v-if="item.typeNameList && item.typeNameList[0]">{{ item.typeNameList[0].length>6?item.typeNameList[0].substr(0,5)+"...":item.typeNameList[0]}}</span>
                  <span v-if="item.typeNameList && item.typeNameList[1]">{{ item.typeNameList[1].length>6?item.typeNameList[1].substr(0,5)+"...":item.typeNameList[1] }}</span>
                  <span v-if="item.typeNameList && item.typeNameList[2]">{{ item.typeNameList[2].length>6?item.typeNameList[2].substr(0,5)+"...":item.typeNameList[2]  }}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="news">
      <div class="title container flex-h flex-vc">
        <h1>{{ column[2] }}<span>NEWS</span></h1>
        <p class="more" @click="$router.push({ name: 'news' })">更多资讯>></p>
      </div>
      <div class="container">
        <ul class="flex-h">
          <li
            class="flex-h"
            v-for="(item, index) in news"
            :key="index"
            @click="
              $router.push({ name: 'newsDetail', query: { id: item.id } })
            "
          >
            <div class="itemLeft">
              <img class="newsImg" :src="item.coverUrl" />
            </div>
            <div class="itemRight">
              <h1>
                {{ item.title }}
              </h1>
              <p>
                {{ item.synopsis }}
              </p>
            </div>
            <div class="nums flex-h flex-vc">
              <img src="@/assets/img/eye.png" />
              <span>{{ item.browseNum }}人查看</span>
              <img src="@/assets/img/time.png" />
              <span>{{ $formatDate(item.releaseTime) }}</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 志愿者风采 -->
    <div class="show">
      <div class="title container flex-h flex-vc" style="margin-bottom: 37px">
        <h1>志愿者风采<span>Volunteer Style</span></h1>
        <p
          class="more"
          @click="$router.push({ name: 'news', query: { typeId: 3 } })"
        >
          更多风采>>
        </p>
      </div>
      <div class="container">
        <div class="showList flex-h">
          <dl
            v-for="(item, index) in scene"
            :key="index"
            @click="
              $router.push({ name: 'newsDetail', query: { id: item.id } })
            "
          >
            <dt>
              <img :src="item.coverUrl" />
            </dt>
            <dd>
              <h1>{{ item.title }}</h1>
              <p>
                {{ item.synopsis }}
              </p>
              <div class="nums flex-h flex-vc">
                <img src="@/assets/img/time.png" />
                <span>{{ $formatDate(item.releaseTime) }}</span>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import { getBanner, getHome, getColumn } from "@/api/index";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      bannerList: [],
      noticeList: [],
      activityList: [],
      queue: [],
      news: [],
      scene: [],
      swiperOption: {
        loop: true,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // // 显示分页
        // pagination: {
        //   el: ".swiper-pagination",
        //   clickable: true //允许分页点击跳转
        // },
        // // 设置点击箭头
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev"
        // }
      },
      noticeOption: {
        direction: "vertical",
        height: 50,
        slidesPerView: 1,
        autoHeight: true, //高度随内容变化
        loop: true,
        autoplay: {
          delay: 2000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
      },
    };
  },
  methods: {
    getBannerList() {
      getBanner().then((res) => {
        this.bannerList = res.data.list;
      });
    },
    getHome() {
      getHome().then((res) => {
        console.log(res);
        let news = res.data.news;
        let notice = res.data.notice;
        let queue = res.data.queue;
        let scene = res.data.scene;
        let activityList = res.data.activityList;
        this.noticeList = notice;
        this.activityList = activityList;
        this.queue = queue;
        this.news = news;
        this.scene = scene;
      });
    },
  },
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    // console.log("this is current swiper instance object", this.swiper);
    // this.swiper.update();
    // this.swiper.slideTo(0);
  },
  created() {
    this.getBannerList();
    this.getHome();
  },
  computed: {
    column() {
      return this.$store.state.column;
    },
  },
};
</script>
<style lang="scss" scoped>
li {
  list-style-type: none;
}
.banner {
  height: 450px;

  .itemBanner {
    cursor: pointer;
    display: block;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
.title {
  justify-content: space-between;
  h1 {
    font-size: 26px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    span {
      margin-left: 13px;
      font-size: 13px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #635252;
    }
  }
  .more {
    cursor: pointer;
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #a79e9e;
  }
}
.notice {
  height: 92px;
  background: linear-gradient(180deg, #fcf8ec 0%, #f8efd4 100%);
  border-radius: 8px;
  box-sizing: border-box;
  // padding-top: 20px;
  margin-top: 25px;
  img {
    height: 100%;
    margin-left: 25px;
  }
  .noticeSwiper {
    margin-left: 28px;

    h2 {
      width: 688px;
      cursor: pointer;
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 900;
      color: #131313;
      line-height: 50px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .time {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #131313;
    }
  }

  .more {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #131313;
    cursor: pointer;
    float: right;
    margin-left: 130px;
  }
}
.activity {
  padding-bottom: 10px;
  .activityList {
    flex-wrap: wrap;
    dl {
      width: 377px;
      height: 490px;
      margin-right: 23px;
      margin-bottom: 33px;
      background: #f8f8f8;
      box-shadow: 0px 6px 4px 0px #d8d8d8;
      border-radius: 0px 0px 10px 10px;
      dt {
        position: relative;
        height: 226px;
        img {
          width: 100%;
          height: 100%;
        }
        .tag {
          width: 96px;
          height: 28px;
          background: #fb3947;
          border-radius: 14px;
          text-align: center;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
          position: absolute;
          top: 13px;
          right: 14px;
        }
      }
      dd {
        h1 {
          height: 28px;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #1a1a1a;
          line-height: 28px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 0 auto;
          margin-top: 22px;
          margin-bottom: 17px;
          margin-left: 15px;
        }
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1a1a1a;
          margin-bottom: 20px;
          margin-left: 15px;
        }
        .button {
          width: 163px;
          height: 44px;
          background: #fb3947;
          border-radius: 22px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 44px;
          text-align: center;
          cursor: pointer;
          margin: 0 auto;
        }
      }
    }
  }
}
.team {
  background: #f8f8f8;
  padding-bottom: 20px;
  .title {
    padding-top: 40px;
    margin-bottom: 30px;
  }

  ul {
    flex-wrap: wrap;
    li {
      cursor: pointer;
      width: 570px;
      height: 266px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px #e9e5e5;
      border-radius: 10px;
      margin-bottom: 36px;
      &:nth-child(odd) {
        margin-right: 60px;
      }
      .itemLeft {
        width: 205px;
        padding-top: 30px;
        .teamImg {
          width: 138px;
          height: 138px;
          border-radius: 50%;
          display: block;
          margin: 0 auto;
        }
        .startList {
          margin-top: 29px;
          img {
            width: 20px;
            margin: 0 5px;
          }
        }
      }
      .itemRight {
        padding-top: 39px;
        h1 {
          width: 352px;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          margin-bottom: 11px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        p {
          width: 352px;
          margin-bottom: 11px;
          font-size: 20px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #737373;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .tags {
          font-size: 20px;
          width: 352px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #737373;
          span {
            display: inline-block;
            height: 31px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fff;
            line-height: 31px;
            text-align: center;
            background: #90ddd4;
            border-radius: 16px;
            padding: 0 14px;
            margin-bottom: 9px;
            margin-right: 14px;
          }
        }
      }
    }
  }
}
.news {
  padding-bottom: 20px;
  .title {
    padding-top: 40px;
    margin-bottom: 30px;
  }

  ul {
    flex-wrap: wrap;
    li {
      position: relative;
      cursor: pointer;
      width: 590px;
      height: 270px;
      background: #ffffff;
      margin-bottom: 28px;
      box-sizing: border-box;
      padding-left: 36px;
      box-sizing: border-box;
      transition: All 0.5s;
      &:nth-child(odd) {
        margin-right: 20px;
      }
      &:hover {
        box-shadow: 0px 4px 8px 0px #b5b5b5;
        border-radius: 10px;
      }
      .itemLeft {
        padding-top: 36px;
        .newsImg {
          width: 250px;
          height: 150px;
        }
        .startList {
          margin-top: 29px;
          img {
            width: 20px;
            margin: 0 5px;
          }
        }
      }
      .itemRight {
        padding-top: 46px;
        margin-left: 24px;
        h1 {
          width: 250px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #636262;
          margin-bottom: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        p {
          width: 250px;
          margin-bottom: 11px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9c9c9c;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .tags {
          font-size: 20px;
          width: 352px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #737373;
          span {
            display: inline-block;
            height: 31px;
            font-size: 20px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #797979;
            line-height: 31px;
            text-align: center;
            background: #dbd7d7;
            padding: 0 14px;
            margin-bottom: 9px;
            margin-right: 14px;
          }
        }
      }
      .nums {
        width: 300px;
        position: absolute;
        bottom: 27px;
        left: 36px;
        img {
          width: 20px;
          margin-right: 2px;
        }
        span {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1a1a1a;
          margin-right: 20px;
        }
      }
    }
  }
}
.show {
  padding-bottom: 30px;
  background: #f8f8f8;
  .title {
    padding-top: 40px;
  }
  .showList {
    flex-wrap: wrap;
    dl {
      cursor: pointer;
      width: 377px;
      height: 414px;
      margin-right: 23px;
      margin-bottom: 33px;
      background: #f8f8f8;
      box-shadow: 0px 6px 4px 0px #d8d8d8;
      border-radius: 0px 0px 10px 10px;
      dt {
        position: relative;
        height: 226px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      dd {
        h1 {
          height: 28px;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #1a1a1a;
          line-height: 28px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 0 auto;
          margin-top: 22px;
          margin-bottom: 17px;
          margin-left: 15px;
        }
        p {
          width: 332px;
          height: 63px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9c9c9c;
          margin-bottom: 15px;
          margin-left: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .nums {
          margin-left: 15px;
          img {
            width: 20px;
            margin-right: 2px;
          }
          span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #1a1a1a;
          }
        }
      }
    }
  }
}
</style>
